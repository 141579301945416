<template>
  <div class="create">
    <el-row class="create-title-container">
      <el-col :span="18">
        <div class="container">
          <p class="container-name">{{drink.name}} </p>
          <router-back class="container-back"></router-back>
        </div>
        <p class="remark">更新饮品不会现有设备饮品产生影响</p>
        <el-steps size="small" :active="current" :simple="true" finish-status="success">
          <el-step v-for="item in steps" :key="item.title" :title="item.title"/>
        </el-steps>
      </el-col>

      <el-col :span="6" class="tip-container">
        <img class="tip" src="../../../assets/img/tip.png">
      </el-col>

    </el-row>

    <div class="create-container">
      <div class="create-container-item">
        <p class="create-container-item-title">
          <span style="display: inline-block;margin-right: 8px">{{steps[current].title}}</span>
          <el-button v-if="steps[current].pull" type="text"> <i data-v-4603057f="" class="iconfont icon-transaction"></i> 拉取饮品参数</el-button>
        </p>

        <create-basic v-if="current ===0"
                      :select="false"
                      :cups="drink.drinkSpec"
                      :drink="drink" class="create-container-basic"
                      @next="current = current+1"/>
        <create-material v-if="current ===1" :drink="drink" class="create-container-material"
                         @preview="current = current-1"
                         @next="current = current+1"/>
        <create-param v-if="current ===2" :select="false"
                      :load="false"
                      :drink="drink" class="create-container-param"
                      @preview="current = current-1"
                      @next="handleCommit"/>
      </div>
    </div>
  </div>

</template>

<script>
    export default {
        name: '',
        components: {
          CreateBasic: (resolve) => require(['../components/create_basic'], resolve),
          CreateParam: (resolve) => require(['../components/create_param'], resolve),
          CreateMaterial: (resolve) => require(['../components/create_material'], resolve),
        },
        mounted: function () {
          this.$api('equipment.device.drinkDetail',{
            uuid: this.$route.params.uuid,
            deviceUuid: this.$route.params.deviceUUID,
        }).then(data => {
              this.drink = data
          })
        },
        data: function () {
            return {
              data: [],
              steps: [
                {
                  title: '基础配置',
                },
                {
                  title: '物料损耗',
                },
                {
                  title: '饮品参数',
                  pull: true,
                },

              ],
              current: 0,
              drink: {
                name: '',
                engName: '',
                image: '',
                explain: '',
                modelUuid: '',
                drinkSpec: [],
                params: '',
              }
            }
        },
        methods: {
          handleCommit() {
            this.$api('equipment.device.drinUpdate',{},
              {...this.drink, deviceUuid: this.$route.params.deviceUUID}).then(() => {
              this.$message.success('更新成功')
               this.$router.go(-1)
            })
          }
        },
    }
</script>

<style lang="scss" scoped>
  .create {
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    &-title-container {
      padding: $middle-space $container-padding;
      padding-bottom: $small-space;
      background-color: $color-white;

      ::v-deep .el-steps--simple{
        padding: 0 %8;
        border-radius: 0;
        background: white;
        .el-step__title{
          @include font-medium-s();
        }
        .el-step__arrow:before{
          display: none;
        }
        .el-step__arrow:after{
          width: 80%;
          transform: none;
          height: 1px;
        }
      }

      .tip-container {
        text-align: right;

        .tip {
          width: 184px;
        }
      }


      .remark {
        margin: $small-space 0;
        @include font-little();
        color: $gray-7;
      }

      .container {
        display: flex;

        &-name {
          @include font-large-s();
        }

        &-back {
          padding-left: 4px;
          padding-top: 4px;
        }


      }

    }

    &-container {
      margin: $middle-space;
      margin-bottom: 0;


      &-item {
        padding: $container-padding 0;
        width: 60%;
        min-width: 640px;
        margin: auto;
        &-title {
          @include font-large-s();
          margin-bottom: $middle-space;
          padding-bottom: $small-space;
          border-bottom: 1px solid $content-border-color;
          padding-left: $small-space;
        }
      }

      background-color: $color-white;
      //border-radius: @box-radius;
      &-basic {

      }
    }
  }
</style>
